import "./form.css"
import "twin.macro"
import { Link, navigate } from "gatsby"
import { useForm } from "react-hook-form"
import AnredeSelect from "./anrede-select"
import axios from "axios"
import Box from "./box"
import Checkbox from "./checkbox2"
import Column from "./column"
import Columns from "./columns"
import ColumnsThree from "./columns-three"
import ErrorMessage from "./error-message"
import Field from "./field"
import Inline from "./inline"
import Input from "./input2"
import PronunSelect from "./pronomen-select"
import Radio from "./radio"
import React, { useState } from "react"
import scrollTo from 'gatsby-plugin-smoothscroll';
import Submit from "./submit"
import SuccessMessage from "./success-message"
import TextArea from "./textarea"
import WithoutCheckbox from "./without-checkbox"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const ApplicationForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onBlur",
    defaultValues: {

      participation: "ganztags",
      participation_active: false,
      participation_stand: false,

      eat_vegan: false,
      eat_glutenfree: false,
      eat_lactosefree: false,

      assistant_needed: false,
      assistant_needed_text: "",
      support_needed: false,
      support_needed_text: "",
      child_support_needed: false,
      child_support_needed_text: "",

      sonstiges: "",

      picturespermission: false,
      gdprpermission: false,
      storagedata: false,

      title: "",
      firstname: "",
      lastname: "",
      pronomen: "",
      anrede: "",
      email: "",

      workshop_stark_fuer_maedchen: false,
      workshop_ungerechte_carearbeit: false,
      workshop_gleichstellungsbeauftragte_als_agentinnen: false,
      workshop_die_zukunft_der_arbeit: false,
      workshop_transformationsprozesse_in_kommunen: false,
      workshop_der_care_gap: false,
      workshop_anti_sexismus_workshop: false,
      workshop_gemeinsam_auf_dem_weg_zu_paritaet: false,
      workshop_umgang_mit_geschlechtsspezifischem_hass: false,
      workshop_queerversitaet: false,
      workshop_spiel_des_gleichgestellten_lebens: false,
      workshop_die_kommunale_oekologische_transformation: false,
      workshop_maedchen_machen_politik: false,
      workshop_vernetzung_im_dreilaendereck: false,
      workshop_transformation_in_der_beratung: false,
      workshop_haeusliche_pflege: false,
    },
  })

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [duplicateUserError, setDuplicateUserError] = useState(false)
  const [selectedCheckbox, setCountCheckboxes] = useState(0)

  const assistantNeeded = watch("assistant_needed")
  const supportNeeded = watch("support_needed")
  const childSupportNeeded = watch("child_support_needed")

  const limitCheckbox = 3

  const submitForm = async (data) => {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      const response = await axios.post(
        `/api/attends`,
        JSON.stringify(data),
        options
      )
      setIsSuccessfullySubmitted(response.status === 200)
    } catch (e) {
      if (e.response.status >= 400) {
        if (e.response.status === 403) {
          setDuplicateUserError(true)
          return
        }
        setSubmitError(true)
      }
    }
  }

  const [accordionStates, setAccordionStates] = useState({
    step1: {
      uuid: 'step1',
      expanded: true,
    },
    step2: {
      uuid: 'step2',
      expanded: false,
    },
    step3: {
      uuid: 'step3',
      expanded: false,
    },
    step4: {
      uuid: 'step4',
      expanded: false,
    }
  })

  const clickAccordionButton = (step, type) => {

    let temp = {...accordionStates}

    if (type == 'forward')
    {
      eval('temp.step' + step + '.expanded = !temp.step' + step + '.expanded');
      eval('temp.step' + (step + 1) + '.expanded = !temp.step' + (step + 1) + '.expanded');
      scrollTo('.stepanchor' + step)
    }
    else if (type == 'backward')
    {
      eval('temp.step' + step + '.expanded = !temp.step' + step + '.expanded');
      eval('temp.step' + (step - 1) + '.expanded = !temp.step' + (step - 1) + '.expanded');
      scrollTo('.stepanchor' + (step - 1))
    }
    setAccordionStates(temp)
  }

  const accOnChange = (uuid) =>
  {
    var part1name = eval('accordionStates.'+uuid+'.expanded');
  }

  const handleChange = (e) =>
  {
    let isChecked = e.target.checked ? 1 : -1

    if ((selectedCheckbox < limitCheckbox) || isChecked < 1) {

      setCountCheckboxes(selectedCheckbox + isChecked)

    } else {

      e.preventDefault()

    }
  }

  return (
    <>
      {!isSuccessfullySubmitted && (
        <>

          <span tw="mb-8 block text-base">Pflichtfeld*</span>

          <form onSubmit={handleSubmit(submitForm)}>

            <Accordion onChange={(uuid) => accOnChange(uuid)}>

                {/** STEP 1 */}

                <AccordionItem uuid="step1" tw="mb-5" dangerouslySetExpanded={accordionStates.step1.expanded}>
                    <AccordionItemHeading>
                        <AccordionItemButton tw="bg-sand-500 h-24 pt-8 pl-6" className="stepanchor1">
                          <div tw="text-xl font-bold">
                            <div tw="block relative w-[50px] h-[50px] bg-white [border-radius: 50%] float-left -translate-y-2.5 [margin: 0 20px 0 8px]">
                              <span tw="absolute top-[50%] left-[50%] [font-family: BSG-EqualBold,ui-sans-serif,system-ui,sans-serif] font-normal -translate-x-[50%] -translate-y-[50%]">1</span>
                            </div>
                            Teilnahme</div>
                        </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel tw="w-full h-full">
                      <Field>
                        <Box sand withoutPadding tw="">
                        <div tw="mb-4 text-sm">Zur besseren Planbarkeit möchten wir Sie bitten, vorab anzugeben, ob Sie am gesamten Programm teilnehmen werden oder nur am Vormittagsprogramm oder ausschließlich an der Abendveranstaltung interessiert sind.</div>


                          <Radio
                            label="<div>Ich möchte ganztägig <b>(von 10:00 Uhr bis 22:00 Uhr)</b> dabei sein.</div>"
                            value="ganztags"
                            register={register("participation")}
                          />

                          <Radio
                            label="<div>Ich möchte nur tagsüber <b>(von 10:00 Uhr bis 17:30 Uhr)</b> teilnehmen.</div>"
                            value="tags"
                            register={register("participation")}
                          />

                          <Radio
                            label="<div>Ich möchte ausschließlich an der Abendveranstaltung mit Get-together <b>(von 18:30 Uhr bis 22:00 Uhr)</b> teilnehmen.</div>"
                            value="abends"
                            register={register("participation")}
                          />

                        <a
                          tw="mt-6 bg-lilac-500 px-7 py-3 inline-block text-xl font-bold uppercase tracking-widest text-black  !no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300 cursor-pointer"
                          title="WEITER"
                          onClick={() => clickAccordionButton(1, 'forward')}
                          role="link"
                        >
                          WEITER
                        </a>

                        </Box>
                      </Field>
                    </AccordionItemPanel>

                </AccordionItem>

                {/** STEP 2 */}

                <AccordionItem uuid="step2" tw="mb-5" dangerouslySetExpanded={accordionStates.step2.expanded}>
                    <AccordionItemHeading>
                      <AccordionItemButton tw="bg-sand-500 h-24 pt-8 pl-6" className="stepanchor2">
                          <div tw="text-xl font-bold">
                            <div tw="block relative w-[50px] h-[50px] bg-white [border-radius: 50%] float-left -translate-y-2.5 [margin: 0 20px 0 8px]">
                              <span tw="absolute top-[50%] left-[50%] [font-family: BSG-EqualBold,ui-sans-serif,system-ui,sans-serif] font-normal -translate-x-[50%] -translate-y-[50%]">2</span>
                            </div>
                            Workshops</div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel tw="w-full h-full">

                      <Field>
                        <Box sand withoutPadding>
                          <Field>
                            <div tw="mb-4 text-sm font-bold">
                              <p>Bitte beachten Sie, dass einige Workshops eine feste Teilnehmendenzahl haben. Hier können Sie bis zu drei Präferenzen angeben. Wir werden versuchen, auf jeden Fall einen Ihrer Wünsche zu berücksichtigen. Allerdings können wir Ihnen aus organisatorischen Gründen leider erst vor Ort mitteilen, welcher Workshop dies ist.</p>
                              <p>Bitte geben Sie maximal drei Workshops an.</p>
                            </div>

                            <a href="/programmuebersicht" target="_blank">
                              <div tw="mb-5 text-sm underline">Zur Programmübersicht</div>
                            </a>

                            <Checkbox onClick={(e) => handleChange(e)} title="BAG Mädchen*politik e. V. & KuKMA: Gemeinsam stark für Mädchen*!" label="Empowerment durch Vernetzung von Jugend- und Gleichstellungspolitik" link="/kalendar/gemeinsam-stark-fuer-maedchen/" tooltiptext="Mehr erfahren" register={register("workshop_stark_fuer_maedchen")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Bund der Deutschen Katholischen Jugend (BDKJ) & Sozialistische Jugend Deutschlands - Die Falken: (Un-)gerechte Care-Arbeit?" label="Jugendverbandliche Perspektiven auf eine gerechtere Demokratie" link="/kalendar/un-gerechte-care-arbeit-jugendverbandliche-perspektiven-auf-eine-gerechtere-demokratie/" tooltiptext="Mehr erfahren" register={register("workshop_ungerechte_carearbeit")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Bundesarbeitsgemeinschaft kommunaler Frauenbüros und Gleichstellungsstellen: Gleichstellungsbeauftragte als Agent*innen des Wandels" label="" link="/kalendar/gleichstellungsbeauftragte-als-agentinnen-des-wandels/" tooltiptext="Mehr erfahren" register={register("workshop_gleichstellungsbeauftragte_als_agentinnen")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Bundesstiftung Gleichstellung in Kooperation mit Deutsche Gesellschaft für Zeitpolitik, DGB Vereinbarkeit gestalten und DGB Was verdient die Frau?: Die Zukunft der Arbeit: Neue Arbeitszeitmodelle als Chance für Gleichstellung?" label="" link="/kalendar/die-zukunft-der-arbeit-neue-arbeitszeitmodelle-als-chance-fur-gleichstellung/" tooltiptext="Mehr erfahren" register={register("workshop_die_zukunft_der_arbeit")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Bundesstiftung Gleichstellung in Kooperation mit der Bundesarbeitsgemeinschaft kommunaler Frauenbüros und Gleichstellungsstellen. Transformationsprozesse in Kommunen geschlechtergerecht gestalten" label="Tipps und Tricks von und für kommunale Gleichstellungsbeauftragte" link="/kalendar/transformationsprozesse-in-kommunen-geschlechtergerecht-gestalten/" tooltiptext="Mehr erfahren" register={register("workshop_transformationsprozesse_in_kommunen")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Equal Care Day: Der Care Gap beginnt im Kinderzimmer." label="Nachhaltige und solidarische Strategien für mehr Wertschätzung, Sichtbarkeit und eine faire Verteilung der Sorgearbeit" link="/kalendar/der-care-gap-beginnt-im-kinderzimmer/" tooltiptext="Mehr erfahren" register={register("workshop_der_care_gap")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Feministische Medizin e. V.: Anti-Sexismus-Workshop - WIR reagieren gemeinsam" label="Methodentraining gegen Sexismus im Alltag für Arbeitende oder Inanspruchnehmende des Gesundheitssystems" link="/kalendar/anti-sexismus-workshop-wir-reagieren-gemeinsam/" tooltiptext="Mehr erfahren" register={register("workshop_anti_sexismus_workshop")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Frauen in die Aufsichtsräte (FidAR) e. V.: Gemeinsam auf dem Weg zu Parität in Führungspositionen" label="" link="/kalendar/gemeinsam-auf-dem-weg-zu-paritat-in-fuhrungspositionen/" tooltiptext="Mehr erfahren" register={register("workshop_gemeinsam_auf_dem_weg_zu_paritaet")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="HateAid: Umgang mit geschlechtsspezifischem Hass und Anfeindungen im Netz" label="" link="/kalendar/umgang-mit-geschlechtsspezifischem-hass-und-anfeindungen-im-netz/" tooltiptext="Mehr erfahren" register={register("workshop_umgang_mit_geschlechtsspezifischem_hass")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="iQt - Institut für Queer Theory: Queerversität als Prinzip planetarischer Gerechtigkeit" label="" link="/kalendar/queerversitat-als-prinzip-planetarischer-gerechtigkeit/" tooltiptext="Mehr erfahren" register={register("workshop_queerversitaet")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Katholische Frauengemeinschaft Deutschlands e. V. Bundesverband: Spiel des gleichgestellten Lebens" label="Ein interaktives Spiel zu zeit- und gesellschaftspolitischen Hürden für echte Gleichstellung im Lebensverlauf" link="/kalendar/spiel-des-gleichgestellten-lebens/" tooltiptext="Mehr erfahren" register={register("workshop_spiel_des_gleichgestellten_lebens")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Klima-Allianz Deutschland e. V., LIFE - Bildung Umwelt Chancengleichheit e. V., GenderCC - Women: Die kommunale ökologische Transformation geschlechtergerecht umsetzen" label="" link="/kalendar/die-kommunale-okologische-transformation-geschlechtergerecht-umsetzen/" tooltiptext="Mehr erfahren" register={register("workshop_die_kommunale_oekologische_transformation")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Kompetenzzentrum für Kinder- und Jugendbeteiligung im Land Brandenburg und Frauenpolitischer Rat Land Brandenburg e. V.: Mädchen* machen Politik" label="Perspektiven für die Kooperation kommunaler Gleichstellungsarbeit und Jugendbeteiligung" link="/kalendar/madchen-machen-politik/" tooltiptext="Mehr erfahren" register={register("workshop_maedchen_machen_politik")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Landesfrauenrat Sachsen e. V.: Vernetzung im Dreiländereck" label="Grenzüberschreitender Austausch zwischen Deutschland, Polen und Tschechien" link="/kalendar/vernetzung-im-dreilandereck/" tooltiptext="Mehr erfahren" register={register("workshop_vernetzung_im_dreilaendereck")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="SKM Bundesverband e. V.: Transformation in der Beratung - Männer als Zielgruppe für Hilfsangebote" label="Männerfokussierte Beratung als notwendiger Beitrag zum Erreichen von Geschlechtergerechtigkeit" link="/kalendar/transformation-in-der-beratung-manner-als-zielgruppe-fur-hilfsangebote/" tooltiptext="Mehr erfahren" register={register("workshop_transformation_in_der_beratung")} tw="mt-10" />
                            <Checkbox onClick={(e) => handleChange(e)} title="Zukunftsforum Familie e. V.:  Wertschätzung und Geschlechtergerechtigkeit in der häuslichen Pflege" label="Wie kann die informelle Pflege zukunftssicher gemacht werden, ohne dass Frauen alleine die Kosten dafür tragen müssen?" link="/kalendar/wertschatzung-geschlechtergerechtigkeit-in-der-hauslichen-pflege/" tooltiptext="Mehr erfahren" register={register("workshop_haeusliche_pflege")} tw="mt-10" />

                            <div tw="mb-4 text-sm font-bold">
                              <p tw="">Die weiteren Programmangebote ohne Auswahloption können Sie sich ebenfalls schon jetzt gerne vormerken und am Veranstaltungstag spontan entscheiden, ob Sie an diesen Workshops teilnehmen möchten.</p>
                            </div>

                            <WithoutCheckbox title="Amadeu Antonio Stiftung: Mit einer bundesweiten Aufklärungskampagne gegen Antifeminismus" label="Erkenntnisse und Impulse, wie eine Kampagne gesellschaftlichen Wandel fördern und Antifeminismus entgegenwirken kann" link="/kalendar/mit-einer-bundesweiten-aufklarungskampagne-gegen-antifeminismus/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Arbeitnehmerkammer Bremen: Closing Gender Gaps - Gleichstellung auf dem Arbeitsmarkt regional gestalten" label="Mit regionalen Gleichstellungsstrategien mehr Frauen in gut bezahlte Erwerbsarbeit bringen" link="/kalendar/closing-gender-gaps-gleichstellung-auf-dem-arbeitsmarkt-regional-gestalten/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Brandenburgische Technische Universität (BTU) Cottbus-Senftenberg, Hochschule Zittau-Görlitz (HSZG) & TRAWOS, BBSR Cottbus: Geschlechtergerechter Strukturwandel der Lausitz" label="Perspektiven auf dem Weg aus der Kohle und seine wirtschaftliche und politische Gestaltung." link="/kalendar/geschlechtergerechter-strukturwandel-der-lausitz/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Bundesstiftung Gleichstellung: Wandel durch feministische Führungs- und Organisationskultur?" label="Geschlechtergerechtigkeit am Arbeitsplatz neu denken" link="/kalendar/wandel-durch-feministische-fuhrungs-und-organisationskultur/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Bundesstiftung Gleichstellung: Geschlechtergerechtigkeit im Aufenthaltsrecht?" label="Ein Gleichstellungs-Check des Aufenthaltsgesetzes" link="/kalendar/geschlechtergerechtigkeit-im-aufenthaltsrecht/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Bundesstiftung Gleichstellung: Wege zu einer geschlechtergerechten sozial-ökologischen Transformation in Deutschland" label="Der Sektor Mobilität auf dem Prüfstand" link="/kalendar/wege-zu-einer-geschlechtergerechten-sozial-okologischen-transformation-in-deutschland/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Bundesstiftung Gleichstellung. Jenseits der Zweigeschlechtlichkeit: Herausforderung oder Chance für Gleichstellung?" label="" link="/kalendar/jenseits-der-zweigeschlechtlichkeit-herausforderung-oder-chance-fur-gleichstellung/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Deutscher Juristinnenbund & Business and Professional Women (BPW) Germany e. V. | Equal Pay Day Kampagne: Entgelt gleich gemacht" label="Arbeitsentgelte können diskriminierungsfrei gestaltet werden – Vorgaben und Hebel der neuen Entgelttransparenzrichtlinie" link="/kalendar/entgelt-gleich-gemacht/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="EAF Berlin / Bündnis Gemeinsam gegen Sexismus: Gemeinsam gegen Sexismus im Mittelstand und Handwerk" label="Vorstellung von Maßnahmen und Handlungsempfehlungen für Betriebe" link="/kalendar/exakter-titel-wird-noch-nachgereicht/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Hochschule Fulda, Kompetenzzentrum Professionalisierung und Qualitätssicherung haushaltsnaher Dienstleistungen: (K)eine Zeit für Care-Arbeit" label="Der Gender Care Gap und die Veränderungspotenziale durch haushaltsnahe Dienstleistungen" link="/kalendar/keine-zeit-fur-care-arbeit/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Initiative ParitätJetzt. Parität in der Politik: Der Weg zur Gleichstellung" label="Über die Notwendigkeit und Umsetzung einer Paritätsgesetzgebung für die Bundesrepublik Deutschland" link="/kalendar/paritat-in-der-politik-der-weg-zur-gleichstellung/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>
                            <WithoutCheckbox title="Sächsisches Staatsministerium der Justiz und für Demokratie, Europa und Gleichstellung (SMJusDEG). Förderung als Instrument der Gleichstellungsarbeit" label="Strukturwandel und demografischem Wandel geschlechtergerecht begegnen" link="/kalendar/forderung-als-instrument-der-gleichstellungsarbeit/" tooltiptext="Mehr erfahren" ></WithoutCheckbox>


                          </Field>

                          <button
                            tw="mt-6 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                            title="ZURÜCK"
                            onClick={() => clickAccordionButton(2, 'backward')}
                          >
                            ZURÜCK
                          </button>

                          <a
                            tw="inline-block mt-6 bg-lilac-500 px-7 py-3 pt-4 text-xl font-bold uppercase tracking-widest text-black !no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300 cursor-pointer"
                            title="WEITER"
                            onClick={() => clickAccordionButton(2, 'forward')}
                            role="link"
                          >
                            WEITER
                          </a>

                        </Box>
                      </Field>
                    </AccordionItemPanel>
                </AccordionItem>

                {/** STEP 3 */}

                <AccordionItem uuid="step3" tw="mb-5" dangerouslySetExpanded={accordionStates.step3.expanded}>
                    <AccordionItemHeading>
                      <AccordionItemButton tw="bg-sand-500 h-24 pt-8 pl-6" className="stepanchor3">
                          <div tw="text-xl font-bold">
                            <div tw="block relative w-[50px] h-[50px] bg-white [border-radius: 50%] float-left -translate-y-2.5 [margin: 0 20px 0 8px]">
                              <span tw="absolute top-[50%] left-[50%] [font-family: BSG-EqualBold,ui-sans-serif,system-ui,sans-serif] font-normal -translate-x-[50%] -translate-y-[50%]">3</span>
                            </div>
                            Persönliche Daten
                          </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel tw="w-full h-full">

                    <Field>
                      <Box sand>
                        <Field>
                          <ColumnsThree>
                            <Field>
                              <Input
                                register={register("title")}
                                fullwidth
                                label="Titel"
                              />
                            </Field>

                            <Field>
                              <Input
                                register={register("firstname", {
                                  required: true,
                                })}
                                fullwidth
                                label="Vorname*"
                              />
                              {errors?.firstname ? (
                                <div tw="relative">
                                  <div tw="absolute top-0 left-0 text-sm text-red-500">
                                    Dies ist ein Pflichtfeld
                                  </div>
                                </div>
                              ) : null}
                            </Field>
                            <Field>
                              <Input
                                register={register("lastname", {
                                  required: true,
                                })}
                                required
                                fullwidth
                                label="Nachname"
                              />
                              {errors?.lastname ? (
                                <div tw="relative">
                                  <div tw="absolute top-0 left-0 text-sm text-red-500">
                                    Dies ist ein Pflichtfeld
                                  </div>
                                </div>
                              ) : null}
                            </Field>
                          </ColumnsThree>
                          <Columns>
                            <Field>
                              <PronunSelect
                                register={register("pronomen")}
                                label="Pronomen"
                              />
                            </Field>

                            <Field>
                              <AnredeSelect
                                register={register("anrede")}
                                label="Anrede"
                              />
                            </Field>

                            <Field>
                              <Input
                                fullwidth
                                register={register("email", {
                                  required: true,
                                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                })}
                                required
                                label="E-Mail-Adresse"
                              />
                              {errors?.email && errors?.email.type === "required" && (
                                <div tw="relative">
                                  <div tw="absolute top-0 left-0 text-sm text-red-500">
                                    Dies ist ein Pflichtfeld
                                  </div>
                                </div>
                              )}
                              {errors?.email && errors?.email.type === "pattern" && (
                                <div tw="relative">
                                  <div tw="absolute top-0 left-0 text-sm text-red-500">
                                    Dieses Feld muss eine gültige E-Mail-Adresse enthalten
                                  </div>
                                </div>
                              )}
                            </Field>
                          </Columns>

                          <Column>
                            <Field tw="pt-4">
                              <Checkbox
                                label="Ich bin aktiv in die Workshop-Gestaltung eingebunden (z. B. Moderator*in, Panelist*in)"
                                register={register("participation_active")}
                              />
                              <Checkbox
                                label="Ich vertrete meine Organisation an einem Messestand"
                                register={register("participation_stand")}
                              />
                            </Field>

                            <Field>
                              <Field>
                                <div tw="mb-5 text-sm">Ich esse</div>
                              </Field>
                              <Checkbox
                                label="vegan"
                                register={register("eat_vegan")}
                              />
                              <Checkbox
                                label="glutenfrei"
                                register={register("eat_glutenfree")}
                              />
                              <Checkbox
                                label="laktosefrei"
                                register={register("eat_lactosefree")}
                              />

                              <div tw="mb-5 text-sm">Beim Gleichstellungstag werden grundsätzlich vegetarische Speisen angeboten.</div>
                            </Field>


                            <Field lgSpacing>
                              <div tw="relative col-span-2">
                                <div tw="flex flex-col items-start">
                                  <Checkbox
                                    register={register("assistant_needed")}
                                    label="Ich bringe eine Assistenzperson mit"
                                  />
                                  {assistantNeeded ? (
                                    <TextArea
                                      register={register("assistant_needed_text")}
                                      placeholder="z. B. Begleitassistenz"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </Field>
                            <Field lgSpacing>
                              <div tw="relative col-span-2">
                                <div tw="flex flex-col items-start">
                                  <Checkbox
                                    register={register("support_needed")}
                                    label="Ich brauche Unterstützung"
                                  />
                                  {supportNeeded ? (
                                    <TextArea
                                      register={register("support_needed_text")}
                                      placeholder="z. B. Verdolmetschung in Dt. Gebärdensprache, Audiodeskription"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </Field>
                            <Field lgSpacing>
                              <div tw="relative col-span-2">
                                <div tw="flex flex-col items-start">
                                  <Checkbox
                                    register={register("child_support_needed")}
                                    title="Ich benötige eine Kinderbetreuung (bitte Alter des Kindes/der Kinder angeben)"
                                    label="Bitte Alter des Kindes/der Kinder und gewünschten Betreuungszeitraum angeben. Eine Betreuung ist für Kinder im Alter von 1—10 Jahren möglich. Die Betreuung vor Ort ist zwischen 9 und 18 Uhr möglich."
                                  />
                                  {childSupportNeeded ? (
                                    <TextArea
                                      register={register("child_support_needed_text")}
                                      placeholder="z. B. 3 Jahre, 9.30—18:00 Uhr"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </Field>
                          </Column>
                        </Field>

                        <Field lgSpacing>
                          <div tw="block cursor-pointer flex-wrap text-sm">
                            Sonstiges
                          </div>
                          <TextArea fullwidth rows="6" register={register("sonstiges")} label="Sonstiges" />
                        </Field>

                        <Field lgSpacing>
                          <div tw="relative col-span-2">
                            <div tw="flex flex-col items-start space-y-3">
                              <Checkbox
                                register={register("picturespermission", {
                                  required: true,
                                })}
                                required
                                label="Ich willige in die Anfertigung von Foto- und Filmaufnahmen meiner Person und die Veröffentlichung durch die Bundesstiftung Gleichstellung im Rahmen ihrer Presse- und Öffentlichkeitsarbeit ein. Meine Einwilligung schließt die Veröffentlichung in Druckerzeugnissen, im Internet und auf Social Media sowie die Übertragung im Wege eines Livestreams ein.*"
                              />
                              {errors?.picturespermission ? (
                                <div tw="relative text-sm text-red-500">
                                  Dies ist ein Pflichtfeld
                                </div>
                              ) : null}
                              <Checkbox
                                register={register("gdprpermission", { required: true })}
                                label="Ich habe den <a href='/zusammenarbeit-und-zusammensein-am-gleichstellungstag' target='_blank'>Gemeinschaftsregeln</a> der Veranstaltung zur Kenntnis genommen und erkenne die Regeln für ein gutes Miteinander an.*"
                                required
                              />
                              {errors?.gdprpermission ? (
                                <div tw="relative text-sm text-red-500">
                                  Dies ist ein Pflichtfeld
                                </div>
                              ) : null}
                              <Checkbox
                                register={register("storagedata", { required: true })}
                                label="Hiermit stimme ich der Speicherung meiner personenbezogenen Daten im Rahmen des Anmeldeprozesses zu.*"
                                required
                              />
                              {errors?.storagedata ? (
                                <div tw="relative text-sm text-red-500">
                                  Dies ist ein Pflichtfeld
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Field>

                        <Field>
                          <div tw="mb-5 text-sm">
                            <b>Datenverarbeitungshinweis:</b> Wir verarbeiten Ihre personenbezogenen Daten ausschließlich im Rahmen der Durchführung des 2. Gleichstellungstages. Weitere Informationen entnehmen Sie bitte der aktuellen Datenschutzerklärung <a href="https://www.bundesstiftung-gleichstellung.de/datenschutzerklaerung/" target="_blank">auf unserer Website</a>.</div>
                        </Field>

                        <div tw="col-span-2 pb-4">
                          <button
                            tw="mt-6 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                            title="ZURÜCK"
                            onClick={() => clickAccordionButton(3, 'backward')}
                          >
                            ZURÜCK
                          </button>
                          <Submit loading={isSubmitting}>Absenden</Submit>
                        </div>
                      </Box>
                    </Field>

                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>

            {submitError ? (
              <ErrorMessage
                message={
                  "Leider gab es einen Fehler. Bitte versuchen Sie es noch einmal oder kontaktieren Sie uns unter: "
                }
              >
                <a
                  href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                  tw="text-base"
                >
                  gleichstellungstag@bundesstiftung-gleichstellung.de
                </a>
              </ErrorMessage>
            ) : null}
            {duplicateUserError ? (
              <ErrorMessage message="Die*der Nutzer*in mit dieser E-Mail-Adresse wurde bereits registriert." />
            ) : null}
            <div tw="col-span-2 pb-12">
              {/*<Submit loading={isSubmitting}>Absenden</Submit>*/}
            </div>
          </form>
        </>
      )}
      {isSuccessfullySubmitted && (
        <div tw="mt-12 w-full">
          <SuccessMessage />
        </div>
      )}
    </>
  )
}

export default ApplicationForm
