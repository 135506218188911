import React from "react"
import "twin.macro"
import Box from "./box"
import Field from "./field"

const SuccessMessage = () => {
  return (
    <Field lgSpacing>
      <Box sand>
        <div tw="mb-6 text-4xl font-bold pt-8">Guten Tag,</div>
        <div tw="max-w-xl text-lg">
          <p>
            herzlichen Dank für Ihre Anmeldung zum 2. bundesweiten Gleichstellungstag am 5. November 2024 in Berlin. Hiermit bestätigen wir den Eingang Ihrer Anmeldung.
          </p>
          <p>
            <b>Bitte beachten Sie: Diese Bestätigung ist noch keine Teilnahmebestätigung.</b> Ihre Anmeldung wird zunächst von uns geprüft, da die Teilnehmendenzahl leider begrenzt ist. Eine verbindliche Bestätigung über Ihre Teilnahme mit näheren Informationen senden wir Ihnen rechtzeitig für Ihre weitere Planung zu. Nach Ende des Anmeldezeitraums, voraussichtlich Anfang Oktober 2024, werden wir uns wieder bei Ihnen melden.<br />
            Wir danken Ihnen schon jetzt für Ihr Interesse am Gleichstellungstag.
          </p>
          <p>Mit vielen Grüßen aus der Bundesstiftung Gleichstellung</p>
          <p>das Gleichstellungstags-Team</p>
        </div>
      </Box>
    </Field>
  )
}

export default SuccessMessage
