import React from "react"
import "twin.macro"

const PronomenSelect = ({
  name,
  label,
  multiple = false,
  required = false,
  register,
}) => {
  return (
    <div className="custom-select" tw="w-full">
      <label tw="mb-2 block font-regular text-sm leading-none">
        {label}
        {required && "*"}
      </label>
      <select
        tw="w-full border-2 border-transparent px-2 pt-2.5 pb-2.5 text-base outline-none focus:border-anthracite-500 focus:ring-transparent md:px-3"
        {...register}
        multiple={multiple}
        required={required}
        defaultValue={"Auswählen"}
      >
        <option disabled>Auswählen</option>
        <option value="sie/ihr">sie/ihr</option>
        <option value="er/ihm">er/ihm</option>
        <option value="they/them">they/them</option>
        <option value="keine Angabe">keine Angabe</option>

      </select>
    </div>
  )
}

export default PronomenSelect
