import "twin.macro"
import { Tooltip } from 'react-tooltip'
import Icon from "../icon"
import InfoIcon from "../../images/svgs/icon-info.svg"
import React from "react"
import ReactHtmlParser from 'react-html-parser';

const Checkbox = ({
  title,
  label,
  tooltiptext,
  link
}) => {
  return (
    <div tw="mb-4">
      <label tw="flex items-center">
        {title && (
          <div tw="block flex-wrap text-sm font-bold">
            {title}
          </div>
        )}
        {!title && label && (
          <div tw="block flex-wrap text-sm">
            {ReactHtmlParser(label)}
          </div>
        )}

        {tooltiptext && (
          <>
            <a href={link} target="_blank" data-tooltip-id="tooltip" data-tooltip-content={tooltiptext}>
              <Icon svg={InfoIcon} tw="h-4 w-4 ml-2" />
            </a>
            <Tooltip id="tooltip" />
          </>
        )}

      </label>
      { title && label && (
      <div tw="block flex-wrap text-sm">
        {ReactHtmlParser(label)}
      </div>
      )}
    </div>
  )
}

export default Checkbox
