import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const Field = ({
  children,
  smSpacing = false,
  lgSpacing = false,
  ...props
}) => {
  const smSpacingStyles = tw`mb-3`
  const lgSpacingStyles = tw`mb-12`
  const defaultSpacingStyles = tw`mb-6`
  return (
    <div
      css={[
        tw`relative`,
        !smSpacing && !lgSpacing && defaultSpacingStyles,
        smSpacing && smSpacingStyles,
        lgSpacing && lgSpacingStyles,
      ]}
      {...props}
    >
      {children}
    </div>
  )
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
  smSpacing: PropTypes.bool,
  lgSpacing: PropTypes.bool,
}

export default Field
