import "twin.macro"
import { Tooltip } from 'react-tooltip'
import Icon from "../icon"
import InfoIcon from "../../images/svgs/icon-info.svg"
import React from "react"
import ReactHtmlParser from 'react-html-parser';

const Checkbox = ({
  title,
  label,
  link,
  tooltiptext,
  value = "",
  required = false,
  register,
  onClick,
}) => {
  return (
    <div tw="mb-4">
      <label tw="flex cursor-pointer items-center">
        <input
          tw="h-5 w-5 flex-shrink-0 cursor-pointer appearance-none rounded-none border border-anthracite-500 bg-white bg-contain bg-center bg-no-repeat align-top text-lilac-500 transition duration-200 checked:border-lilac-500 checked:bg-lilac-500 focus:outline-none focus:ring-2 focus:ring-lilac-500 dark:border-gray-600 dark:bg-white dark:ring-offset-gray-800 dark:focus:ring-lilac-500"
          type="checkbox"
          value={value}
          {...register}
          onClick={onClick}
        />

        {title && (
          <div tw="ml-2 block cursor-pointer flex-wrap text-sm md:ml-3 font-bold">
            {title}
          </div>
        )}

        {!title && label && (
          <div tw="ml-2 block cursor-pointer flex-wrap text-sm md:ml-3">
            {ReactHtmlParser(label)}
          </div>
        )}

        {tooltiptext && (
          <>
            <a href={link} target="_blank" data-tooltip-id="tooltip" data-tooltip-content={tooltiptext}>
              <Icon svg={InfoIcon} tw="h-4 w-4 ml-2" />
            </a>
            <Tooltip id="tooltip" />
          </>
        )}

      </label>

      { title && label && (
      <div tw="ml-7 block cursor-pointer flex-wrap text-sm md:ml-8">
        {ReactHtmlParser(label)}
        {required && "*"}
      </div>
      )}
    </div>
  )
}

export default Checkbox
