import React from "react"
import { graphql } from "gatsby"
import "twin.macro"
import Container from "../components/container"
import ApplicationForm from "../components/form/application-form"
import BackButton from "../components/back-button"
import "../css/gutenberg.css"

const FormTemplate = ({ data }) => {
  return (
    <>
      <Container>
        <div tw="mt-6 mb-11">
          <BackButton />
        </div>

        <div tw="mb-6 lg:mb-10">
          <h1 tw="mb-10 max-w-5xl text-3xl font-bold lg:mb-18 lg:text-5xl">
            zusammen:wirken <br /> 2. Gleichstellungstag
          </h1>
          <div tw="grid grid-cols-12 gap-5" className="wp-content">
            <div tw="col-span-12 -mx-4 bg-sand-500 px-4 py-8 text-sm hyphens-auto sm:-mx-10 sm:px-10 lg:col-span-2 lg:mx-0 lg:bg-transparent lg:px-0 lg:pb-0 lg:pt-1.5">
              <strong>Veranstaltungsort</strong>
              <br />
              bcc Berlin Congress
              <br />
              Center
              <br />
              Alexanderstraße 11<br />
              10178 Berlin
            </div>
            <div tw="col-span-12 lg:col-span-10">
              <h2 tw="text-3xl font-bold">Anmeldung</h2>
              <p tw="text-xl">Wenn Sie beim 2. bundesweiten Gleichstellungstag am 5. November 2024 in Berlin vor Ort dabei sein möchten, melden Sie sich bitte über dieses Formular an. Sie erhalten nach dem Absenden des Formulars eine Bestätigungsnachricht, dass Ihre Anmeldung bei uns eingegangen ist. Diese Bestätigung ist noch keine Teilnahmebestätigung, denn die Anmeldungen werden zunächst geprüft, da die Teilnehmendenzahl leider begrenzt ist. Eine verbindliche Bestätigung über Ihre Teilnahme senden wir Ihnen rechtzeitig für Ihre weitere Planung nach Ende des Anmeldezeitraums zu.</p>
              <p tw="text-xl">Die Programmpunkte auf der Hauptbühne im Kuppelsaal werden ganztägig über einen Livestream auf unserer Website zu verfolgen sein. Dafür ist keine Anmeldung notwendig. Eine Übersetzung in Deutsche Gebärdensprache wird auch im Livestream verfügbar sein.</p>
              <p tw="text-xl">Sollten Sie selbst mit Ihrer Organisation im Rahmen des Fachkongresses einen Workshop anbieten oder über die Programmpunkte der Bundesstiftung Gleichstellung aktiv eingebunden sein, bitten wir Sie, dies beim Ausfüllen der Anmeldung anzugeben. Dies gilt auch, wenn Sie Ihre Organisation bei der Fachmesse an einem Messestand vertreten.</p>
              <ApplicationForm></ApplicationForm>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default FormTemplate

//export { Head } from "../components/head"

/*export const pageQuery = graphql`
  query HomeById {
    seo: wpPage(id: { eq: "cG9zdDoxNTMz" }) {
      ...SeoFragment
    }
  }
`*/