import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

const Box = ({ children, sand = false, withoutPadding = false }) => {
  const sandStyles = tw`bg-sand-500`
  const withoutPaddingStyle = tw`pt-0`
  return (
    <div
      css={[sand && sandStyles, withoutPadding && withoutPaddingStyle]}
      tw="pl-8 pr-8 pb-8 pt-0">
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Box
