import React, { Children } from "react"
// import PropTypes from "prop-types"
import "twin.macro"

const Columns = ({ children }) => {
  return (
    <div tw="grid w-full grid-cols-3 gap-x-5 gap-y-6 md:gap-y-4">
      {Children.map(children, child => (
        <div tw="relative col-span-2 md:col-span-1">
          {React.cloneElement(child)}
        </div>
      ))}
    </div>
  )
}

Columns.propTypes = {}

export default Columns
