import React from "react"
import ReactHtmlParser from 'react-html-parser';
import "twin.macro"

const Radio = ({ label, value = "", required = false, register, ...props }) => (
  <label tw="flex flex-row items-center">
    <input
      {...register}
      tw="h-5 w-5 flex-shrink-0 m-1 cursor-pointer appearance-none rounded-full border border-anthracite-500 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-lilac-500 checked:bg-lilac-500 focus:outline-none"
      type="radio"
      value={value}
      {...props}
    />
    <div tw="ml-2 flex cursor-pointer flex-wrap text-sm md:ml-3">
      {ReactHtmlParser(label)}
      {required && "*"}
    </div>
  </label>
)

export default Radio
